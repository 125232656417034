(function($) {

    'use strict';

    var Login = function(element, options) {
        this.$element = $(element);
        this.options = options;

        this.$form                       = this.$element.closest('form');
        this.$email                      = this.$form.find('[data-login-validate-email]');
        this.$birthDay                   = this.$form.find('[data-social-birth-day] ');
        this.$birthMonth                 = this.$form.find('[data-social-birth-month]');
        this.$birthYear                  = this.$form.find('[data-social-birth-year]');
        this.$gender                     = this.$form.find('[data-social-gender]');
        this.$stateRegister              = this.$form.find('[data-login-state-registration]');
        this.$stateRegisterExempt        = this.$form.find('[data-login-check-state-registration]');
        this.$minimumAge                 = 18;
        this.$actualYear                 = new Date().getFullYear();
        this.$actualMonth                = new Date().getMonth() + 1;
        this.$actualDay                  = new Date().getDate();
        this.$birthMonthSelect           = $('#RegistrationForm_month');
        this.$birthDaySelect             = $('#RegistrationForm_day');
        this.$birthYearSelectOptions     = $('#RegistrationForm_year option');
        this.$birthMonthSelectOptions    = $('#RegistrationForm_month option');
        this.$birthDaySelectOptions      = $('#RegistrationForm_day option');
    };

    Login.DEFAULTS = {
        'legalPerson': '1',
        'naturalPerson': '2',
        'messages': {
            'birthdayRequired': 'Preencha os dados do campo de data de nascimento.',
            'invalidMinimumAge': 'A idade mínima requirida é de 18 anos.'
        }
    };

    Login.prototype.validateLogin = function() {
        this.$email.val($.trim(this.$email.val()));
        this.validateEmail();

        if (this.isLoginValid() === false) {
            this.setAsInvalid(this.$element);
        }
    };

    Login.prototype.validateEmail = function() {
        this.$element.removeClass('is-valid').removeClass('is-invalid');
        this.$form.find('.errorMessage').remove();

        if (this.isLoginValid()) {
            this.setAsValid(this.$element);
        }
    };

    Login.prototype.isLoginValid = function() {
        var isEmailValid = /\S+@\S+\.\S+/.test(this.$email.val());
        var isCPFvalid = this.$email.val().replace(/[^0-9]/g, '').length === 11;

        if (this.$form.data('login-validate') === 'create') {
            isCPFvalid = false;
        }

        return (isEmailValid || isCPFvalid);
    };

    Login.prototype.changePersonType = function() {
        var form = this.$element.closest('form');
        var naturalPerson = form.find('[data-form-group="natural"]');
        var legalPerson = form.find('[data-form-group="legal"]');

        if (this.$element.val() == this.options.naturalPerson) {
            this.removeValidation(legalPerson);
            this.showValidation(naturalPerson);
            this.changeTaxIdentification('CPF');
        } else {
            this.removeValidation(naturalPerson);
            this.showValidation(legalPerson);
            this.changeTaxIdentification('CNPJ');
        }
    };

    Login.prototype.removeValidation = function(element) {
        element.attr('disabled', 'disabled');
        element.closest('[data-login-person-type-container]').hide();
    };

    Login.prototype.showValidation = function(element) {
        element.removeAttr('disabled');
        element.closest('[data-login-person-type-container]').show().removeClass('hide');
    };

    Login.prototype.toggleStateRegistration = function(element, forceDisabled) {
        var form = this.$element.closest('form');
        var isDisabled = (this.$element.is(':checked') || forceDisabled) ? 'disabled' : false;

        form.find('[data-login-state-registration]').attr('disabled', isDisabled);
        if (form.find('[data-login-state-registration]').hasClass('parsley-error')) {
            form.find('[data-login-state-registration]').toggleClass("is-invalid");
            form.find('[data-login-state-registration]').siblings('.parsley-errors-list').toggleClass('hide');
        }
    };

    Login.prototype.changeTaxIdentification = function(type) {
        var form = this.$element.closest('form');

        var name = form.find('[data-login-name]');
        var taxIdentification = form.find('[data-login-tax-identification]');
        var taxIdentificationLabel = form.find('[data-login-tax-identification-label]');

        if (type === 'CPF') {
            name.text('Nome');
            VMasker(taxIdentification).maskPattern('999.999.999-99');
            taxIdentificationLabel.text(type);
        } else {
            name.text('Razão social');
            VMasker(taxIdentification).maskPattern('99.999.999/9999-99');
            taxIdentificationLabel.text(type);
        }
    };

    Login.prototype.calculateYearDifference = function() {
        if (this.$birthYear.val() === null || this.$birthYear.val() === '') {
            return 0;
        }

        return this.$actualYear - parseInt(this.$birthYear.val());
    };

    Login.prototype.validateBirthday = function() {
        this.removeInvalidBirthMessage();

        this.setAsValid(this.$birthDay);
        this.setAsValid(this.$birthMonth);
        this.setAsValid(this.$birthYear);

        if (
            this.$birthDay.val() === null  ||
            this.$birthDay.val() === ''    ||
            this.$birthMonth.val() == null ||
            this.$birthMonth.val() === ''  ||
            this.$birthYear.val() == null  ||
            this.$birthYear.val() === ''
            ) {
            this.addInvalidBirthMessage(this.options.messages.birthdayRequired);
            return;
        }

        this.validateMinimumAge();
    };

    Login.prototype.validateMinimumAge = function () {
        var yearDifference = this.calculateYearDifference();

        if (yearDifference > this.$minimumAge) {
            return;
        }

        if (yearDifference == this.$minimumAge && parseInt(this.$birthMonth.val()) < this.$actualMonth) {
            return;
        }

        if (yearDifference == this.$minimumAge && parseInt(this.$birthMonth.val()) == this.$actualMonth && parseInt(this.$birthDay.val()) < this.$actualDay) {
            return;
        }

        if (yearDifference == this.$minimumAge && parseInt(this.$birthMonth.val()) == this.$actualMonth && parseInt(this.$birthDay.val()) == this.$actualDay) {
            return;
        }

        this.addInvalidBirthMessage(this.options.messages.invalidMinimumAge);
    };

    Login.prototype.removeInvalidBirthMessage = function () {
        $('#parsley-id-' + this.$birthDay.attr('data-form-id')).remove();
        $('#parsley-id-' + this.$birthMonth.attr('data-form-id')).remove();
        $('#parsley-id-' + this.$birthYear.attr('data-form-id')).remove();
        $('#parsley-id-' + this.$birthYear.attr('id')).remove();
    };

    Login.prototype.addInvalidBirthMessage = function (message) {
        var errorId = this.$birthYear.attr('id');

        this.setAsInvalid(this.$birthYear);
        this.setAsInvalid(this.$birthMonth);
        this.setAsInvalid(this.$birthDay);

        if (this.$birthYear.attr('data-form-id')) {
            errorId = this.$birthYear.attr('data-form-id');
        }

        if(this.$birthYear.parents('.parsley-birthday').length) {
            this.$birthYear.parents('.parsley-birthday').append(
                '<ul class="parsley-errors-list filled parsley-errors-birthday" id="parsley-id-' + errorId +
                '"><li class="parsley-required">' + message +
                '</li></ul>'
            );
        } else {
            this.addInvalidValidationMessage(this.$birthYear.parent(), errorId, message);
        }
    };

    Login.prototype.disableSelectMonthAndDayOnBirth = function () {
        this.$birthMonthSelect.prop('disabled', true);
        this.$birthDaySelect.prop('disabled', true);
    };

    Login.prototype.enableSelectMonthAndDayOnBirth = function () {
        this.$birthMonthSelect.prop('disabled', false);
        this.$birthDaySelect.prop('disabled', false);
    };

    Login.prototype.enableOptionsDisabledOnTheDateOfBirth = function() {
        for (var i = 1; i < this.$birthMonthSelectOptions.length; i++) {
            if (parseInt(this.$birthMonthSelectOptions[i].value) > this.$actualMonth) {
                this.$birthMonthSelectOptions[i].disabled = false;
            }
        }

        for (var i = 1; i < this.$birthDaySelectOptions.length; i++) {
            if (parseInt(this.$birthDaySelectOptions[i].value) >= this.$actualDay) {
                this.$birthDaySelectOptions[i].disabled = false;
            }
        }
    };

    Login.prototype.disableOptionsThatAllowMinorOnTheDateOfBirth = function() {
        var yearDifference = this.calculateYearDifference();

        this.enableSelectMonthAndDayOnBirth();

        if (yearDifference < this.$minimumAge) {
            this.disableSelectMonthAndDayOnBirth();
        }

        if (yearDifference > this.$minimumAge) {
            this.enableOptionsDisabledOnTheDateOfBirth();
        }

        if (yearDifference == this.$minimumAge && this.$birthMonth.val() < this.$actualMonth) {
            this.enableOptionsDisabledOnTheDateOfBirth();
        }

        if (yearDifference == this.$minimumAge) {
            for (var i = 0; i < this.$birthMonthSelectOptions.length; i++) {
                if (parseInt(this.$birthMonthSelectOptions[i].value) > this.$actualMonth && this.$birthMonthSelectOptions[i].value != this.$birthMonthSelect.val()) {
                    this.$birthMonthSelectOptions[i].disabled = true;
                }
            }
        }

        if (yearDifference == this.$minimumAge && this.$birthMonth.val() == this.$actualMonth && this.$birthDaySelectOptions[i].value != this.$birthDaySelect.val()) {
            for (var i = 0; i < this.$birthDaySelectOptions.length; i++) {
                if (parseInt(this.$birthDaySelectOptions[i].value) >= this.$actualDay) {
                    this.$birthDaySelectOptions[i].disabled = true;
                }
            }
        }
    };

    Login.prototype.disableInvalidOptionsOnDateBirth = function() {
        if (this.$birthYearSelectOptions[0].disabled === false) {
            this.$birthYearSelectOptions[0].disabled = true;
            this.$birthMonthSelectOptions[0].disabled = true;
            this.$birthDaySelectOptions[0].disabled = true;
        }
    };

    Login.prototype.validateStateRegister = function() {
        var errorId = this.$stateRegister.attr('id');

        if (this.$stateRegister.attr('data-form-id')) {
            errorId = this.$stateRegister.attr('data-form-id');
        }

        $('#parsley-id-' + this.$stateRegister.attr('id')).remove();
        $('#parsley-id-' + this.$stateRegister.attr('data-form-id')).remove();

        $('#parsley-id-' + errorId);

        if (this.$stateRegister.val() == '' && !this.$stateRegisterExempt.prop("checked")) {
            this.addInvalidValidationMessage($('.auth-form-state-registration'), errorId, 'Campo obrigatório');
            this.setAsInvalid(this.$stateRegister);
            return;
        }

        this.setAsValid(this.$stateRegister);
    };

    Login.prototype.validateGender = function() {
        var errorId = this.$gender.attr('id');

        if (this.$gender.attr('data-form-id')) {
            errorId = this.$gender.attr('data-form-id');
        }

        $('#parsley-id-' + this.$gender.attr('id')).remove();
        $('#parsley-id-' + this.$gender.attr('data-form-id')).remove();

        if (this.$gender.val() == '') {
            this.addInvalidValidationMessage($('[data-gender-box]'), errorId, 'Campo obrigatório');
            this.setAsInvalid(this.$gender);
            return;
        }

        this.setAsValid(this.$gender);
    }

    Login.prototype.setAsInvalid = function(element) {
        element.removeClass('is-valid').addClass('is-invalid');
    };

    Login.prototype.setAsValid = function(element) {
        element.removeClass('is-invalid').addClass('is-valid');
    };

    Login.prototype.addInvalidValidationMessage = function(element, errorId, message) {
        element.append(
            '<ul class="parsley-errors-list filled" id="parsley-id-' + errorId +
            '"><li class="parsley-required">' + message +
            '</li></ul>'
        );
    };

    Login.prototype.emailExists = function() {
        $(document).trigger('dft.events-monitoring');

        var params = {
            'email': this.$email.val(),
            'page': 'checkout',
            'b2014': 1
        };

        if (this.isLoginValid() === false) {
            this.setAsInvalid(this.$email);
            this.addErrorMessage(i18n.invalidEmail);
            return;
        }

        $.ajax({
            'url': '/checkout/index/CheckEmailExist/',
            'dataType': 'json',
            'data': params,
            'complete': $.proxy(this, 'loaded')
        });
    };

    Login.prototype.loaded = function(response) {
        var data = JSON.parse(response.responseText);
        var shouldRedirectToCheckout = window.location.pathname.indexOf('checkout');

        if (data.response === false && shouldRedirectToCheckout > 0) {
            return window.location.href = '/checkout/index/redirectCheckout';
        }

        if (data.template) {
            this.setAsInvalid(this.$email);

            // email exists on database
            $('body').modal('destroy');
            $('body').removeData('dft.modal');
            $('body').modal({ 'innerClassName': 'modal-email-exists', 'content': data.template });
        }
    };

    Login.prototype.addErrorMessage = function(message) {
        if (this.$form.find('.errorMessage').length === 0) {
            this.$email.after('<div class="errorMessage">' + message + '</div>');
        }
    };

    Login.prototype.forceLegalPerson = function() {
        var isLegalPerson = $('#RegistrationForm_customer_personality_1').is(':checked');

        if (isLegalPerson) {
            this.changePersonType();
            this.toggleStateRegistration(this.$element, true);
        }
    };

    Login.prototype.clearForm = function(message) {
        var elements = this.$element.siblings('.auth-form-box');

        elements.find('[data-login-validate-email]').val('');
        elements.find('[data-login-validate-password]').val('');
        elements.find('.is-invalid').remove();
        elements.find('.is-valid').remove();
        elements.find('.description-error').remove();
        elements.find('.error').removeClass('error');
        elements.find('.errorMessage').remove();
    };

    $.fn.login = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.login');
            var options = $.extend({}, Login.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.login', (data = new Login(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('input paste, keyup', '[data-login-validate-email]', function(e) {
        $(this).login('validateEmail');
    });

    $(document).on('blur', '[data-login-validate-email]', function(e) {
        $(this).login('validateLogin');
    });

    $(document).on('change', '[data-social-birth-year]', function(e) {
        $(this).login('validateBirthday');
        $(this).login('disableInvalidOptionsOnDateBirth');
        $(this).login('disableOptionsThatAllowMinorOnTheDateOfBirth');
    });

    $(document).on('change', '[data-social-birth-month]', function(e) {
        $(this).login('validateBirthday');
        $(this).login('disableOptionsThatAllowMinorOnTheDateOfBirth');
    });

    $(document).on('change', '[data-social-birth-day]', function(e) {
        $(this).login('validateBirthday');
    });

    $(document).on('change', '[data-login-state-registration]', function(e) {
        $(this).login('validateStateRegister');
    });

    $(document).on('change', '[data-social-gender]', function(e) {
        $(this).login('validateGender');
    });

    $(document).on('submit', '[data-login-validate="create"]', function(e) {
        e.preventDefault();
        $(this).login('emailExists');
    });

    $(document).on('submit', '[data-login-validate="createLogin"]', function(e) {
        $(this).login('validateBirthday');
        $(this).login('validateStateRegister');
        $(this).login('validateGender');
    });

    $(document).on('click', '[data-login-check-state-registration]', function(e) {
        $(this).login('toggleStateRegistration');
    });

    $(document).on('change', '[data-login-person-type]', function(e) {
        $(this).login('changePersonType');
    });

    $(document).on('click','.password-toggle', function(e) {
        var obj = $('.input-box-password');
        var input = obj.find('input');
        var status = !JSON.parse(obj.attr('show-password'))
        obj.attr('show-password', status)
        input.attr('type', status?'text':'password')
    });

    $(document).on('click','.password-toggle-change', function(e) {
        var obj = $('.input-box-password.change');
        var input = obj.find('input');
        var status = !JSON.parse(obj.attr('show-password-change'))
        obj.attr('show-password-change', status)
        input.attr('type', status?'text':'password')
    });

    $(document).on('click','.password-toggle-confirm', function(e) {
        var obj = $('.input-box-password.confirm');
        var input = obj.find('input');
        var status = !JSON.parse(obj.attr('show-password-confirm'))
        obj.attr('show-password-confirm', status)
        input.attr('type', status?'text':'password')
    });

    $(document).on('click','.password-toggle-old', function(e) {
        var obj = $('.input-box-password.old');
        var input = obj.find('input');
        var status = !JSON.parse(obj.attr('show-password-old'))
        obj.attr('show-password-old', status)
        input.attr('type', status?'text':'password')
    });

    $(document).on('keyup', '.cpf_cnpj', function(e) {
        
        var value = $('.cpf_cnpj').val();
        value = value.replace(/\D/g, '');
        
        if (value.length <= 11) {
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        } else {
            value = value.replace(/^(\d{2})(\d)/, '$1.$2');
            value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
            value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
            value = value.replace(/(\d{4})(\d)/, '$1-$2');
        }
        
        $('.cpf_cnpj').val(value);   
    });

    var selectedOption = "account-form-registered-customer";
    $(document).on('click', 'input[data-accordion-trigger="account"]', function(e) {
        if ($(this).attr('id') == selectedOption) {
            if ($(this).attr('id') == "account-form-registered-customer") {
                selectedOption = 'account-form-new-customer';
                $('#account-form-registered-customer, #account-form-new-customer').prop('checked', false);
                $('#account-form-new-customer').prop('checked', true);
            } else {
                selectedOption = 'account-form-registered-customer';
                $('#account-form-registered-customer, #account-form-new-customer').prop('checked', false);
                $('#account-form-registered-customer').prop('checked', true);
            }
        } else {
            selectedOption = $(this).attr('id');
        }
    });

    $(document).ready(function() {
        VMasker($('[data-login-tax-identification]')).maskPattern('999.999.999-99');
        $("#address-form, #my-account-address-form").on({
            keyup: function() {
                var phone = this.value,
                    digits = phone.replace(/\D/g, '');

                VMasker($(this)).unMask();

                if (digits.length > 9) {
                    VMasker($(this)).maskPattern("(99) 99999-9999");
                } else {
                    VMasker($(this)).maskPattern("(99) 9999-9999");
                }
            },
            keypress: function(e) {
                var unicode = e.charCode ? e.charCode : e.keyCode;
                if (unicode != 8 && unicode != 9) {
                    if (unicode < 48 || unicode > 57) {
                        return false
                    }
                }
            }
        }, ".mask-phone-number");
        $('#login-account-create').login('forceLegalPerson');

        var domains = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com", "icloud.com"];
        
        var selectedOptionIndex = -1;
        var emailFields = document.getElementsByClassName("autocomplete-email");
        var dropdownContents = document.getElementsByClassName("dropdown-content");

        for (var i = 0; i < emailFields.length; i++) {
            var emailField = emailFields[i];
            var dropdownContent = dropdownContents[i];

            emailField.addEventListener("input", createInputHandler(emailField, dropdownContent));
            emailField.addEventListener("keydown", createKeydownHandler(emailField, dropdownContent));
        }

        function createInputHandler(field, content) {
            return function() {
                var inputText = field.value;
                content.innerHTML = "";

                if (inputText.includes("@")) {
                    var parts = inputText.split("@");
                    var domain = parts[1];

                    var matchedDomains = domains.filter(function(value) {
                        return value.startsWith(domain);
                    });

                    matchedDomains.forEach(function(value, index) {
                        var domainOption = document.createElement("a");
                        domainOption.href = "#";
                        domainOption.textContent = parts[0] + "@" + value;

                        if (index === selectedOptionIndex) {
                            domainOption.classList.add("selected");
                        }

                        domainOption.addEventListener("click", function() {
                            field.value = this.textContent;
                            content.innerHTML = "";
                            selectedOptionIndex = -1;
                        });

                        content.appendChild(domainOption);
                    });

                    if (matchedDomains.length > 0) {
                        content.style.display = "block";
                    } else {
                        content.style.display = "none";
                    }
                } else {
                    content.style.display = "none";
                }
            };
        }

        function createKeydownHandler(field, content) {
            return function(event) {
                if (event.key === "ArrowDown") {
                    event.preventDefault();

                    var options = content.getElementsByTagName("a");
                    if (options.length > 0) {
                        selectedOptionIndex = (selectedOptionIndex + 1) % options.length;

                        for (var i = 0; i < options.length; i++) {
                            if (i === selectedOptionIndex) {
                                options[i].classList.add("selected");
                            } else {
                                options[i].classList.remove("selected");
                            }
                        }
                    }
                } else if (event.key === "ArrowUp") {
                    event.preventDefault();

                    var options = content.getElementsByTagName("a");
                    if (options.length > 0) {
                        if (selectedOptionIndex === -1) {
                            selectedOptionIndex = options.length - 1;
                        } else {
                            selectedOptionIndex = (selectedOptionIndex - 1 + options.length) % options.length;
                        }

                        for (var i = 0; i < options.length; i++) {
                            if (i === selectedOptionIndex) {
                                options[i].classList.add("selected");
                            } else {
                                options[i].classList.remove("selected");
                            }
                        }
                    }
                } else if (event.key === "Enter") {
                    var options = content.getElementsByTagName("a");
                    if (selectedOptionIndex !== -1 && options.length > 0) {
                        event.preventDefault();
                        field.value = options[selectedOptionIndex].textContent;
                        content.innerHTML = "";
                        selectedOptionIndex = -1;
                        $('.dropdown-content').hide();
                        domainDropdown.style.display = "none";
                    }
                }
            };
        }

        document.addEventListener("click", function(event) {
            var isEmailField = false;

            for (var i = 0; i < emailFields.length; i++) {
                if (event.target === emailFields[i]) {
                    isEmailField = true;
                    break;
                }
            }

            if (!isEmailField) {
                for (var i = 0; i < dropdownContents.length; i++) {
                    dropdownContents[i].style.display = "none";
                }

                selectedOptionIndex = -1;
            }
        });
    });

})(jQuery);

if (setCookie == undefined) {
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
}

if (configCatClient != undefined) {
    configCatClient
        .getValueAsync("hashloginfront", false, userObject)
        .then(function (value) {
            console.log("hashloginfront: " + typeof value);

            if (value) {
                setCookie("new_login_front", "1", 1);
                callGaEvent("ligado");
            } else {
                setCookie("new_login_front", "", -1);
                callGaEvent("desligado");
            }
        });
}

function callGaEvent(status) {
    if (dataLayer == undefined) return;

    if (window.location.href.indexOf("account/login") >= 1) {
        dataLayer.push({
            event: "ab_test_event_ga4",
            ab_test_name: "new_login_front",
            ab_test_variant: status,
        });
    }
}